import React, {useState, useEffect} from 'react';
import axios from 'axios';
import './style.scss';
import {SimplicateApi} from '../../utils';

export default function RelationInfo({project}) {
  const [clientManagers, setClientManagers] = useState([]);
  const [relationManagers, setRelationManagers] = useState([]);

  

  useEffect(() => {
    const headers = {
      headers: {'x-auth-token': localStorage.getItem('jwt'), 'Access-Control-Allow-Origin': '*'},
    };

    axios.get(`${process.env.REACT_APP_API_HOST}/cm/projects`, headers).then(res => {
      setClientManagers(res.data.map(project => project.name));

      let temp = [];
      res.data.forEach(project => {
        project.project.forEach(item => {
          if (!temp.includes(item.relationManager)) {
            temp.push(item.relationManager);
          }
        });
      });

      setRelationManagers(temp);
    });
  }, []);

  const updateManager = e => {
    const SimplicateRequest = new SimplicateApi(
      process.env.REACT_APP_SIMPLICATE_KEY,
      process.env.REACT_APP_SIMPLICATE_SECRET
    );

    const updateValue = e.target.value;
    const projectId = project.projectId;
    const companyId = project.companyId;

    console.log(companyId);

    const options = {
      headers: {'x-auth-token': localStorage.getItem('jwt'), 'Access-Control-Allow-Origin': '*'},
    };

    if (e.target.name === 'rm') {
      axios
        .post(
          `${process.env.REACT_APP_API_HOST}/cm/update-rm`,
          {projectId: projectId, newRelationManager: updateValue, companyId: companyId},
          options
        )
        .then(res => {
          console.log(res);
        });


        const aa =  SimplicateRequest.get(`/hrm/employee?q[name]=${updateValue}&select=id`).then(res => {
          console.log(res.data[0].id);

          const simplicateBody = {
            relation_manager: { id : res.data[0].id},
          }

          SimplicateRequest.post(`/crm/organization/${companyId}`, simplicateBody).then(response => {
            console.log(response);
          });
        });

    }

    if (e.target.name === 'cm') {
      axios
        .post(
          `${process.env.REACT_APP_API_HOST}/cm/update-cm`,
          {projectId: projectId, newClientManager: updateValue},
          options
        )
        .then(res => {
          console.log(res);
        });


        const aa =  SimplicateRequest.get(`/hrm/employee?q[name]=${updateValue}&select=id`).then(res => {
          console.log(res.data[0].id);

          const simplicateBody = {
            project_manager_id:res.data[0].id,
          }

          SimplicateRequest.put(`/projects/project/${projectId}`, simplicateBody).then(response => {
            console.log(response);
          });
        });


    }
  };

  return (
    <div className="relation">
      <div className="relation__manager">
        <h4>Relatie manager</h4>

        <select name="rm" onChange={updateManager}>
          <option selected="selected">{project.relationManager}</option>
          {relationManagers.map(manager =>
            manager ? <option key={Math.random()}>{manager}</option> : null
          )}
        </select>

        <h4>Projectleider</h4>
        <select name="cm" onChange={updateManager}>
          <option selected="selected">{project.projectManager}</option>
          {clientManagers.map(manager =>
            manager ? <option key={Math.random()}>{manager}</option> : null
          )}
        </select>
      </div>

      <div className="relation__employees">
        <h4>Medewerkers</h4>
        <ul>
          {project.employees.map(employee => (
            <>
              <a
                href={`https://emark.simplicate.nl/hrm/employees`}
                key={Math.random()}
                target="_blank"
                rel="noopener noreferrer"
              >
                <li>{employee}</li>
              </a>
            </>
          ))}
        </ul>
      </div>

      <div className="relation__contacts">
        <div>
          <h4>Contactpersoon</h4>
          <ul>
            {project.contactPerson.map(contact => (
              <>
                <a
                  href={`https://emark.simplicate.nl/crm/organizations/${project.companyId}/contacts`}
                  key={Math.random()}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <li>{contact.person.full_name}</li>
                </a>
              </>
            ))}
          </ul>
        </div>

        <div>
          <h4>Telefoonnummer</h4>
          <ul>
            {project.contactPerson.map(contact => (
              <>
                <a
                  href={`tel:${contact.work_phone || contact.work_mobile}`}
                  key={Math.random()}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <li>{contact.work_phone || contact.work_mobile}</li>
                </a>
              </>
            ))}
          </ul>
        </div>

        <div>
          <h4>E-mail:</h4>
          <ul>
            {project.contactPerson.map(contact => (
              <>
                <a
                  href={`mailto:${contact.work_email}`}
                  key={Math.random()}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <li>{contact.work_email}</li>
                </a>
              </>
            ))}
          </ul>
        </div>
      </div>
    </div>
  );
}
