import React, {useEffect} from 'react';
import {withRouter} from 'react-router-dom';
import './styles.scss';
import logo from '../assets/logo-black.png';
import {GoogleButton} from '../components';
import {isAuthenticated} from '../utils';

export default withRouter(function Login({history}) {
  useEffect(() => {
    if (isAuthenticated()) {
      history.push('/home');
    }
  });

  const handleClick = () => {
    window.location.href = process.env.REACT_APP_AUTH_URL;
  };

  return (
    <div className="login-container">
      <div className="login-container__content">
        <img src={logo} className="login-container__logo" alt="" />
        <h3>Inloggen</h3>
        <p>Uitsluitend voor medewerkers en klanten van @e-marketing support</p>
        <GoogleButton handleClick={handleClick} />
      </div>
    </div>
  );
});
