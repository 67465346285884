import React from 'react';
import './style.scss';
import {FaGoogle} from 'react-icons/fa';

export default function Button({handleClick}) {
  return (
    <button className="google-button" onClick={handleClick}>
      <div className="google-button__icon">
        <FaGoogle />
      </div>
      Login met Google
    </button>
  );
}
