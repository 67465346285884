import React from 'react';
import {useStore} from '../../state';

export default function Sort() {
  const {setSortOrder, sortOrder} = useStore();

  const handleSort = e => {
    const value = e.target.value;
    setSortOrder(value);
  };

  console.log(sortOrder);
  return (
    <div className="sort-container">
      <form onChange={handleSort}>
        <div className="radio-toolbar">
          <h4>Actieve abo's</h4>
          <input type="radio" id="activeRevenueAsc" name="radio" value="asc" />
          <label for="activeRevenueAsc">Actieve omzet (oplopend)</label>

          <input type="radio" id="activeRevenueDesc" name="radio" value="desc" />
          <label for="activeRevenueDesc">Actieve omzet (aflopend)</label>
        </div>

        <div className="radio-toolbar">
          <h4>Onhold abo's</h4>
          <input type="radio" id="onholdAsc" name="radio" value="ascIn" />
          <label for="onholdAsc">Onhold totaal (oplopend)</label>

          <input type="radio" id="onholdDesc" name="radio" value="descIn" />
          <label for="onholdDesc">Onhold totaal (aflopend)</label>

          <input type="radio" id="startDateAsc" name="radio" value="ascInDate" />
          <label for="startDateAsc">Verwachte startdatum (oplopend)</label>

          <input type="radio" id="startDateDesc" name="radio" value="descInDate" />
          <label for="startDateDesc">Verwachte startdatum (aflopend)</label>
        </div>

        <div className="radio-toolbar">
          <h4>Openstaande offertes</h4>
          <input type="radio" id="saleAsc" name="radio" value="ascSa" />
          <label for="saleAsc">Open offertes (oplopend)</label>

          <input type="radio" id="saleDesc" name="radio" value="descSa" />
          <label for="saleDesc">Open offertes (aflopend)</label>
        </div>
      </form>
    </div>
  );
}
