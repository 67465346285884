/**
 * From the original Array extract all the projects in an Array format
 * @param {Array} projects 
 * @returns {Array}
 */
 const getNesteProjectValues = (projects) => {
    const copy = [...projects]; 
    const temp = []; 
    
    copy.forEach(item => {
        item.project.forEach(project => {
            temp.push(project); 
        })
    })

    return temp; 
}

export default getNesteProjectValues; 