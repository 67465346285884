const revenueGroups = {
    campMan : 'Campagne mangement',
    startersPakket : 'Dexport starters pakket' , 
    marketingCon : 'Marketing consultancy', 
    seoPrContent : 'SEO PR en content marketing', 
    profitwiser : 'Profitwiser', 
    detachering : 'Detachering', 
    administratie : 'Administratie', 
    duitsadres : 'Duits adres en services', 
    vertalingen : 'Vertalingen',
    mediaEnWebDev : 'Media en web development', 
    marketingPartnerDeals : 'Marketin partner deals', 
    budgetCampMan : 'Budget campagne management',
    dexpay :  'Dexpay', 
    amazonMarketing : 'Amazon marketing' ,
    total : 'Totaal'
}

export default revenueGroups; 