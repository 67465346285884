/**
 * returns a sorted array based on selected filters
 * @function handleSorting
 * @param   {Array}   filteredProjects
 * @param   {Object}  sortingFilters
 * @returns {Array}   sorted Array.
 */
const handleSorting = (filteredProjects, sortOrder) => {
  if (!filteredProjects || !Array.isArray(filteredProjects)) return;
  return handleSortOrder(sortOrder, filteredProjects);
};

/**
 * Takes in a string and a list of projects, based on that string in determines how to sort the projects
 * @function handleSortOrder
 * @param {string} sortOrder
 * @param {Array} filteredProjects
 * @returns {Array} sorted array
 */
const handleSortOrder = (sortOrder, filteredProjects) => {
  switch (sortOrder) {
    case 'desc':
      return filteredProjects.sort((a, b) => b.total - a.total);
    case 'asc':
      return filteredProjects.sort((a, b) => a.total - b.total);
    case 'ascIn':
      return filteredProjects.sort((a, b) => a.totalIn - b.totalIn);
    case 'descIn':
      filteredProjects = filteredProjects.sort((a, b) => b.totalIn - a.totalIn);
      return filteredProjects;
    case 'ascSa':
      filteredProjects = filteredProjects.sort((a, b) => a.totalSale - b.totalSale);
      return filteredProjects;
    case 'descSa':
      filteredProjects = filteredProjects.sort((a, b) => b.totalSale - a.totalSale);
      return filteredProjects;

    case 'ascInDate':
      filteredProjects = filteredProjects.sort((a, b) => {
        if (!a.fieldStartDate) return 1;
        if (!b.fieldStartDate) return -1;
        return new Date(a.fieldStartDate) - new Date(b.fieldStartDate);
      });
      return filteredProjects;

    case 'descInDate':
      filteredProjects = filteredProjects.sort((a, b) => {
        if (!a.fieldStartDate) return 1;
        if (!b.fieldStartDate) return -1;
        return new Date(b.fieldStartDate) - new Date(a.fieldStartDate);
      });
      return filteredProjects;

    default:
      return filteredProjects;
  }
};

export default handleSorting;
