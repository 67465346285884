import React from 'react';
import './style.scss';

export default function ProjectHead({title, value}) {
  return (
    <div className="project-head">
      <h4>{title}</h4> : <span>{value}</span>
    </div>
  );
}
