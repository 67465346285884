import React, {useState, useEffect, useRef} from 'react';
import './style.scss';
import axios from 'axios';
import {BsStarFill} from 'react-icons/bs';
import {useStore} from '../../state';

export default function Filters() {
  const [clientManagers, setClientManagers] = useState([]);
  const [relationManagers, setRelationManagers] = useState([]);
  const [employees, setEmployees] = useState([]);
  const [isChecked, setIsChecked] = useState(false);
  const [query, setQuery] = useState('');
  const [debounced, setDebounced] = useState('');

  const {filters, setFilters} = useStore();

  const handleChange = e => {
    localStorage.setItem(e.target.name, e.target.value);
    setFilters(e);
  };

  useEffect(() => {
    const timeout = setTimeout(() => {
      setFilters({target: {name: 'searchQuery', value: query}});
    }, 300);
    return () => {
      clearTimeout(timeout);
    };
  }, [query]);

  const handleSearch = e => {
    e.preventDefault();
    setQuery(e.target.value);
  };

  //filter values
  useEffect(() => {
    //employees
    const headers = {
      headers: {'x-auth-token': localStorage.getItem('jwt'), 'Access-Control-Allow-Origin': '*'},
    };
    axios.get(`${process.env.REACT_APP_API_HOST}/cm/employees`, headers).then(res => {
      const temp = res.data.map(employee => {
        return employee.name;
      });
      setEmployees(temp);
    });

    //client managers + relation Managers
    if (filters.onholds) {
      axios.get(`${process.env.REACT_APP_API_HOST}/cm/onholds`, headers).then(res => {
        setClientManagers(
          res.data.map(project => {
            return project.name;
          })
        );

        let temp = [];
        res.data.forEach(project => {
          project.project.forEach(item => {
            if (!temp.includes(item.relationManager)) {
              temp.push(item.relationManager);
            }
          });
        });

        setRelationManagers(temp);
      });
    } else {
      axios.get(`${process.env.REACT_APP_API_HOST}/cm/projects`, headers).then(res => {
        setClientManagers(
          res.data.map(project => {
            return project.name;
          })
        );

        let temp = [];
        res.data.forEach(project => {
          project.project.forEach(item => {
            if (!temp.includes(item.relationManager)) {
              temp.push(item.relationManager);
            }
          });
        });

        setRelationManagers(temp);
      });
    }

    const items = {...localStorage};

    Object.keys(items).map(itemKey => {
      if (itemKey !== 'jwt') {
        return setFilters({target: {value: items[itemKey], name: itemKey}});
      }
      return;
    });
  }, [setFilters, filters.onholds]);

  useEffect(() => {
    setFilters({target: {name: 'onholds', value: isChecked}});
  }, [isChecked, setFilters]);

  const handleCheckbox = () => {
    setIsChecked(!isChecked);
  };

  const localEvaluationMonth = localStorage.getItem('evaluationMonth');
  const localQuery = localStorage.getItem('searchQuery');
  const localRelationManager = localStorage.getItem('relationManager');
  const localProjectManager = localStorage.getItem('projectManager');
  const localEmployee = localStorage.getItem('employee');

  return (
    <>
      <div className="title">
        <div className="title__left">
          <div className="title__icon">
            <BsStarFill />
          </div>
          <h2>Filters</h2>
        </div>

        <div className="title__right">
          <span>Enkel niet gestarte klanten</span>
          <input
            type="checkbox"
            name="onholds"
            onChange={e => {
              handleCheckbox();
            }}
          ></input>
        </div>
      </div>

      <div className="filter-container">
        <div className="filter-container__top-row">
          <input
            type="text"
            className="filter-container__search"
            placeholder="Zoeken..."
            name="searchQuery"
            defaultValue={localQuery ? localQuery : ''}
            onChange={handleSearch}
          ></input>

          <select name="evaluationMonth" onChange={handleChange}>
            <option value="default">Evaluatie maand</option>
            {localEvaluationMonth && localEvaluationMonth !== 'default' ? (
              <option selected="selected">{localEvaluationMonth}</option>
            ) : null}

            <option value="januari">Januari</option>
            <option value="februari">Februari</option>
            <option value="maart">Maart</option>
            <option value="april">April</option>
            <option value="mei">Mei</option>
            <option value="juni">Juni</option>
            <option value="juli">Juli</option>
            <option value="augustus">Augustus</option>
            <option value="september">September</option>
            <option value="oktober">Oktober</option>
            <option value="november">November</option>
            <option value="december">December</option>
          </select>
        </div>

        <div className="filter-container__bottom-row">
          <select name="relationManager" onChange={handleChange}>
            <option value="default">Relatiebeheerder</option>
            {localRelationManager && localRelationManager !== 'default' ? (
              <option selected="selected">{localRelationManager}</option>
            ) : null}

            {relationManagers
              .sort()
              .map(
                (relationManager, index) =>
                  relationManager && <option key={index}>{relationManager}</option>
              )}
          </select>

          <select name="projectManager" onChange={handleChange}>
            <option value="default">Project manager</option>

            {localProjectManager && localProjectManager !== 'default' ? (
              <option selected="selected">{localProjectManager}</option>
            ) : null}

            {clientManagers
              .sort()
              .map(
                (clientManager, index) =>
                  clientManager && <option key={index}>{clientManager}</option>
              )}
          </select>

          <select name="employee" onChange={handleChange}>
            <option value="default">Specialist</option>

            {localEmployee && localEmployee !== 'default' ? (
              <option selected="selected">{localEmployee}</option>
            ) : null}

            {employees
              .sort()
              .map((employee, index) => employee && <option key={index}>{employee}</option>)}
          </select>
        </div>
      </div>
    </>
  );
}
