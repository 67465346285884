import React, {useState, useEffect} from 'react';
import {
  Header,
  Filters,
  Kpis,
  Sort,
  ProjectHead,
  RelationInfo,
  DataStudio,
  AccountActions,
  HourTable,
  SideBar,
  HourModal,
} from '../components';
import axios from 'axios';
import {FaChevronRight, FaChevronDown} from 'react-icons/fa';
import {useProjects} from '../hooks';
import {handleSorting} from '../utils';
import {useStore} from '../state';

export default function Projects() {
  const [showSort, setShowSort] = useState(false);
  const [fields, setFields] = useState([]);
  const {sortOrder} = useStore();
  const handleSortClick = () => {
    setShowSort(!showSort);
  };

  let projects = useProjects();

  projects = handleSorting(projects, sortOrder);

  const [modalIsOpen, setModalIsOpen] = useState(false);

  const handleModal = async e => {
    setModalIsOpen(true);
  };

  const handleModalClose = () => {
    setModalIsOpen(false);
  };

  const [modalProject, setModalProject] = useState([]);



    useEffect(() => {
      const headers = {
        headers: {'x-auth-token': localStorage.getItem('jwt'), 'Access-Control-Allow-Origin': '*'}
      };
  
      axios.get(`${process.env.REACT_APP_API_HOST}/cm/project-fields-all`, headers).then(res => {
        setFields(res.data);
      }).catch(e => {
        console.log(e)
      });
  
    },[])


  return (
    <>
      <Header pageName="Client Managers" />

      <div className="filter">
        <div className="filter__left">
          <Filters />
        </div>
        <div className="filter__right">
          <Kpis />
        </div>
      </div>

      <div className="projects" key={Math.random()}>
        <div className="projects__top-row">
          <div>
            <h2>Resultaten</h2>
            <span style={{fontWeight: 'bold'}}>Gevonden projecten:</span>{' '}
            <span>{projects.length}</span>
          </div>

          <div>
            <button className="projects__sort-button" onClick={handleSortClick}>
              Sorteren op
              {showSort ? (
                <FaChevronDown className="projects__sort-button-icon" />
              ) : (
                <FaChevronRight className="projects__sort-button-icon" />
              )}
            </button>
          </div>
        </div>

        {showSort ? (
          <div className="projects__sort">
            <Sort />
          </div>
        ) : null}

        {projects.map((project, index) => (
          <>
            <div className="projects__content" key={project.id}>
              <div className="projects__left" key={project.id}>
                <ProjectHead
                  title="Projectnaam"
                  value={project.projectName}
                  key={project.id}
                ></ProjectHead>
                <RelationInfo project={project} key={project.id} />

      

                <DataStudio url={project.dataStudioUrl} pid={project.projectId} key={project.id} fields={fields}/>
                
                <AccountActions projectId={project.projectId} key={project.projectId} />
                <HourTable project={project} key={project.projectId} />

                <button
                  className="write-hour"
                  onClick={e => {
                    handleModal(e);
                    setModalProject(project);
                  }}
                  value={project.projectId}
                >
                  Uren schrijven
                </button>

                {modalIsOpen ? (
                  <HourModal
                    modalIsOpen={modalIsOpen}
                    projectId={modalProject.projectId}
                    project={modalProject}
                    handleModalClose={handleModalClose}
                  />
                ) : null}
              </div>

              <div className="projects__right" key={Math.random()}>
                <ProjectHead
                  title="Bedrijfsnaam"
                  value={project.companyName}
                  key={Math.random()}
                ></ProjectHead>
                <SideBar key={Math.random()} project={project} />
              </div>
            </div>
          </>
        ))}
      </div>
    </>
  );
}
