import jwtDecode from 'jwt-decode';

const isAuthenticated = () => {
  const token = localStorage.getItem('jwt');
  const dateNow = new Date();
  if (!token) return false;

  const decoded = jwtDecode(token);

  if (decoded.email && decoded.exp < dateNow.getTime() / 1000) {
    localStorage.removeItem('jwt');
    return false;
  }

  return true;
};

export default isAuthenticated;
