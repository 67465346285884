import React , {useEffect,useState} from 'react';
import './style.scss';
import axios from 'axios';

export default function DataStudio({url,pid,fields}) {
  


 const myFields = fields.find(field => {
    return field.projectId === pid
  });

  let dataStudioUrl = 'Geen';
  if(myFields && myFields.dataStudioUrl){
    dataStudioUrl = myFields.dataStudioUrl;
  }
  
  return (
    <div className="datastudio">
      {dataStudioUrl !== undefined && dataStudioUrl !== 'not set' && dataStudioUrl !== 'Geen' && dataStudioUrl.length > 4   ? (
        <iframe
          title="google"
          className="datastudio__iframe"
          src={dataStudioUrl}
          frameBorder="0"
          style={{border: 0}}
          height="600"
          allowFullScreen
        ></iframe>
      ) : (
        <h4>Er is geen data studio url voor dit project</h4>
      )}
    </div>
  );
}
