import jwtDecode from 'jwt-decode';

const getInitials = () => {
  const token = localStorage.getItem('jwt');
  const decoded = jwtDecode(token);
  const name = decoded.name;
  let today = new Date();
  let dd = String(today.getDate()).padStart(2, '0');
  let mm = String(today.getMonth() + 1).padStart(2, '0');
  let yyyy = today.getFullYear();
  today = dd + '-' + mm + '-' + yyyy;

  const initial = `${today} ${name
    .split(' ')
    .map(str => str[0])
    .join('')}`;

  return initial;
};

export default getInitials;
