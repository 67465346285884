import create from 'zustand';

export const useStore = create(set => ({
  filters: {
    searchQuery: null,
    evaluationMonth: null,
    relationManager: null,
    projectManager: null,
    employee: null,
    onholds: false,
    response: null,
    hours: null,
    hours2: null,
  },

  sortOrder: null,

  setSortOrder: value => {
    set({sortOrder: value});
  },

  setFilters: e => {
    set(state =>
      e.target.value === 'default'
        ? {filters: {...state.filters, [e.target.name]: null}}
        : {filters: {...state.filters, [e.target.name]: e.target.value}}
    );
  },
}));
