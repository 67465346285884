import React from 'react';
import './app.scss';
import {BrowserRouter as Router, Switch, Route} from 'react-router-dom';
import {Login, Home, ClientManagers} from './pages';
import {AuthCallback, PrivateRoute} from './components';
import {ToastProvider} from 'react-toast-notifications';

/**
 * this function checks if there is a jwt and if the jwt hasnt expired and contains an email.
 * It is used to handle private routes in front-end
 * @function    isAuthenticated
 * @return      {boolean}
 */

export default function App() {
  return (
    <div>
      <Router>
        <Switch>
          <ToastProvider>
            <Route exact path="/auth/callback" component={AuthCallback} />
            <Route exact path="/" component={Login} />
            <PrivateRoute exact path="/home" component={Home} />
            <PrivateRoute exact path="/client-managers" component={ClientManagers} />
          </ToastProvider>
        </Switch>
      </Router>
    </div>
  );
}
