import React, {useEffect, useState} from 'react';
import {withRouter} from 'react-router-dom';

import queryString from 'query-string';

import LoginError from './LoginError';

export default withRouter(function CallbackContainer({history, location}) {
  const [state, setState] = useState('');

  /**
   * Checks the url bar query and returns a value depending on the url query
   * @function    typeOfQuery
   * @param       {string} qs
   * @return      {string} type of response
   */
  const typeOfQuery = qs => {
    if (qs.err) return 'err';
    if (qs.jwt) return 'jwt';
    return;
  };

  useEffect(() => {
    if (localStorage.getItem('jwt')) history.push('/home');
    const query = queryString.parse(location.search);
    switch (typeOfQuery(query)) {
      case 'err':
        setState(query.err);
        break;
      case 'jwt':
        localStorage.setItem('jwt', query.jwt);
        history.push('/home');
        break;
      default:
        history.push('/');
    }
  }, [history, location.search]);

  return state === 'unauthorized-domain' ? <LoginError></LoginError> : null;
});
