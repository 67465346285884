import React from 'react';
import './style.scss';

export default function ProjectCard({appTitle, appDescription, appIcon, handleClick}) {
  return (
    <div className="project-card" onClick={handleClick}>
      <div className="project-card__icon">{appIcon}</div>
      <div className="project-card__title">
        <h4>{appTitle}</h4>
      </div>
      <div className="divider"></div>
      <div className="project-card__description">
        <span>{appDescription}</span>
      </div>
    </div>
  );
}
