import fetch from 'node-fetch';

/**
 * @todo Add documentation
 * @todo catch in api calls
 */
class SimplicateApi {
  constructor(key, secret) {
    this._key = key;
    this._secret = secret;
    this.baseUrl = 'https://emark.simplicate.nl/api/v2';

    this.options = {
      headers: {
        'Content-Type': 'application/json',
        'Authentication-Key': this._key,
        'Authentication-Secret': this._secret,
      },
    };
  }

  get(endpoint) {
    return fetch(`${this.baseUrl}${endpoint}`, this.options)
      .then(res => res.json())
      .catch(err => {
        console.error(err);
      });
  }

  post(endpoint, body) {
    this.options.method = 'post';
    this.options.body = JSON.stringify(body);
    return fetch(`${this.baseUrl}${endpoint}`, this.options)
      .then(res => res.json())
      .then(json => json)
      .catch(err => console.error(err));
  }


  put(endpoint, body) {
    this.options.method = 'put';
    this.options.body = JSON.stringify(body);
    return fetch(`${this.baseUrl}${endpoint}`, this.options)
      .then(res => res.json())
      .then(json => json)
      .catch(err => console.error(err));
  }
}

export default SimplicateApi;
