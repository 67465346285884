import getNesteProjectValues from './getNesteProjectValues';

/**
 * Filter the projects based on the selected filters object that is passed in to the function
 * @param {Object} filter
 * @param {Array} projects
 * @returns {Array} The filtered array if no filters are selected return empty array
 */
const applyFilters = (filters, projects) => {
  let copy = getNesteProjectValues(projects);

  const {
    projectManager,
    employee,
    evaluationMonth,
    searchQuery,
    relationManager,
    response,
    hours,
    hours2,
  } = filters;

  if (projectManager) {
    copy = copy.filter(project => project.projectManager === projectManager);
  }

  if (relationManager) {
    copy = copy.filter(project => project.relationManager === relationManager);
  }

  if (response) {
    console.log(response);

    // copy = copy.filter(project => project.ratings.some(rating => rating.category === response));
    copy = copy.filter(project => {
      return project.ratings.some(rating => {
        return rating.category === response;
      });
    });

    console.log(copy);
  }

  if (employee) {
    copy = copy.filter(project => project.employees.includes(employee));
  }

  if (evaluationMonth) {
    copy = copy.filter(
      project =>
        project.firstEvaluation === evaluationMonth || project.secondEvaluation === evaluationMonth
    );
  }

  if (searchQuery) {
    copy = copy.filter(
      project =>
        project.companyName.toLowerCase().includes(searchQuery) ||
        project.projectName.toLowerCase().includes(searchQuery)
    );
  }

  if (hours && !employee) {
    copy = copy.filter(project => {
      if (project.projectHours[0]) {
        const firstDate = new Date(project.projectHours[0].created_at);
        const today = new Date();
        const diffMs = today - firstDate;
        const diffDays = Math.floor(diffMs / 86400000); // days
        return diffDays >= 21;
      } else {
        return null;
      }
    });
  }

  if (hours2) {
    copy = copy.filter(project => {
      if (project.projectHours[0]) {
        const firstDate = new Date(project.projectHours[0].created_at);
        const today = new Date();
        const diffMs = today - firstDate;
        const diffDays = Math.floor(diffMs / 86400000); // days
        return diffDays >= 21;
      } else {
        return null;
      }
    });
  }

  if (hours && employee) {
    copy = copy.filter(project => {
      if (project.projectHours.length) {
        const a = project.projectHours.find(project => {
          const firstDate = new Date(project.created_at);
          const today = new Date();
          const diffMs = today - firstDate;

          const diffDays = Math.floor(diffMs / 86400000); // days

          return diffDays <= 21 && project.employee.name === employee;
        });

        if (a) {
          return false;
        } else {
          return true;
        }
      }
    });
  }

  if (
    !searchQuery &&
    !evaluationMonth &&
    !projectManager &&
    !employee &&
    !relationManager &&
    !response &&
    !hours &&
    !hours2
  ) {
    return [];
  }

  return copy;
};

export default applyFilters;
