import React from 'react';
import {Header, AppCard} from '../components';
import {FaRegListAlt} from 'react-icons/fa';
import {useHistory} from 'react-router-dom';

export default function Home() {
  const history = useHistory();
  const handleClick = () => history.push('/client-managers');

  return (
    <>
      <Header pageName="Home" />
      <div className="home-container">
        <AppCard
          appTitle="Client managers"
          appDescription="Informatie voor client managers van Dexport. Hier vind je een overzicht van klanten en hun gegevens"
          appIcon={<FaRegListAlt />}
          handleClick={handleClick}
        />
      </div>
    </>
  );
}
