import React, {useState, useRef, useEffect} from 'react';
import {withRouter} from 'react-router-dom';
import './style.scss';
import logo from '../../assets/logo-white.png';
import {FaAngleDown, FaSignOutAlt} from 'react-icons/fa';
import {useOnClickOutside} from '../../hooks';
import jwtDecode from 'jwt-decode';

export default withRouter(function Header({history, pageName}) {
  const [isOpen, setIsOpen] = useState(false);
  const ref = useRef();
  const handleClick = () => setIsOpen(prev => !prev);
  useOnClickOutside(ref, () => setIsOpen(false));

  const handleLogout = () => {
    localStorage.removeItem('jwt');
    history.push('/');
  };

  const [user, setUser] = useState({});

  useEffect(() => {
    const token = localStorage.getItem('jwt');
    const decoded = jwtDecode(token);
    setUser(decoded);
  }, [setUser]);

  return (
    <>
      <div className="header">
        <img src={logo} alt="" className="header__logo" />
        <div className="header__user-info">
          <img src={user.picture} className="header__user-avatar" alt="user" />
          <div className="header__user-info-settings">
            <button onClick={handleClick}>
              <span>{user.name}</span>
              <FaAngleDown />
            </button>
          </div>

          {isOpen ? (
            <div className="header__settings" ref={ref}>
              <button onClick={handleLogout}>
                Uitloggen <FaSignOutAlt className="logout" />
              </button>
            </div>
          ) : null}
        </div>
      </div>
      <div className="header-title">
        <h4>{pageName}</h4>
      </div>
    </>
  );
});
