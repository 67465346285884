import {useState, useEffect} from 'react';
import axios from 'axios';
import {useStore} from '../state/index';
import {applyFilters} from '../utils';

export default function useProjects() {
  const [projects, setProjects] = useState([]);
  const [filteredProjects, setFilteredProjects] = useState([]);

  const {filters} = useStore();

  useEffect(() => {
    const headers = {
      headers: {'x-auth-token': localStorage.getItem('jwt'), 'Access-Control-Allow-Origin': '*'},
    };

    if (filters.onholds) {
     
      axios
        .get(`${process.env.REACT_APP_API_HOST}/cm/onholds`, headers)
        .then(res => setProjects(res.data));
    } else {
      axios
        .get(`${process.env.REACT_APP_API_HOST}/cm/projects`, headers)
        .then(res => setProjects(res.data));
    }
  }, [filters.onholds]);

  useEffect(() => {
    setFilteredProjects(applyFilters(filters, projects));
  }, [projects, filters]);

  if (Object.values(filters).some(item => item !== null) === true && !filteredProjects.length)
    return filteredProjects;

  return filteredProjects.length ? filteredProjects : [];
}
