import React, {useEffect, useState} from 'react';
import axios from 'axios';
import {getInitials} from '../../utils';
import './style.scss';

export default function AccountActions({projectId}) {
  const [fieldAccountActions, setFieldAccountActions] = useState();
  const [initials, setInitials] = useState();
  const [postValues, setPostValues] = useState('');

  useEffect(() => {
    setInitials(getInitials());
  }, []);

  useEffect(() => {
    setInitials(getInitials());
    let config = {
      headers: {
        'x-auth-token': localStorage.getItem('jwt'),
        'Access-Control-Allow-Origin': '*',
      },
      params: {
        id: projectId,
      },
    };

    axios
      .get(`${process.env.REACT_APP_API_HOST}/cm/project-fields`, config)
      .then(res => {
        const response = res.data[0];
        if (response && response.accountActions) {
          setFieldAccountActions(`${initials}\n ${response.accountActions}`);
        } else {
          setFieldAccountActions(`${initials}\n`);
        }
      })
      .catch(err => console.log(err.response));
  }, [projectId, initials]);

  const handleChange = e => {
    const value = e.target.value;
    setPostValues(prev => {
      return value;
    });
  };

  const handleBlur = () => {
    const body = {projectId: projectId, fields: {accountActions: postValues}};
    const options = {
      headers: {
        'x-auth-token': localStorage.getItem('jwt'),
        'Access-Control-Allow-Origin': '*',
      },
    };

    if (postValues === fieldAccountActions) {
      return;
    } else {
      axios
        .post(`${process.env.REACT_APP_API_HOST}/cm/project-fields`, body, options)
        .then(res => {})
        .catch(err => {
          console.error(err);
        });
    }
  };



  return (
    <div className="actions">
      <h4>Prioriteiten marketing (stap 1 t/m 3):</h4>
      <textarea
        defaultValue={fieldAccountActions}
        onChange={handleChange}
        onBlur={handleBlur}
      ></textarea>
    </div>
  );
}
