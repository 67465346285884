import React, {useEffect, useState} from 'react';
import './style.scss';
import {BsStarFill} from 'react-icons/bs';
import axios from 'axios';
import {useProjects} from '../../hooks';
import {useStore} from '../../state';
import {getNesteProjectValues} from '../../utils';

export default function Kpis() {
  const filteredProjects = useProjects();
  const [allProjects, setAllProjects] = useState([]);
  // const [inactiveProjects, setInactiveProjects] = useState([]);
  const {filters, setFilters} = useStore();

  useEffect(() => {
    const headers = {
      headers: {'x-auth-token': localStorage.getItem('jwt'), 'Access-Control-Allow-Origin': '*'},
    };

    const hoursObject = localStorage.getItem('hours');
    const hoursObject2 = localStorage.getItem('hours2');

    if (hoursObject) {
      const parsedObject = JSON.parse(hoursObject);
      setFilters({target: {name: 'hours', value: parsedObject.value}});
    }

    if (hoursObject2) {
      const parsedObject = JSON.parse(hoursObject2);
      setFilters({target: {name: 'hourss', value: parsedObject.value}});
    }

    if (filters.onholds) {
      axios.get(`${process.env.REACT_APP_API_HOST}/cm/onholds`, headers).then(res => {
        setAllProjects(res.data);
      });
    } else {
      axios.get(`${process.env.REACT_APP_API_HOST}/cm/projects`, headers).then(res => {
        setAllProjects(res.data);
      });
    }

    const response = localStorage.getItem('response');
    if (response) {
      setFilters({target: {name: 'response', value: response}});
    }
  }, [setFilters, filters.onholds]);

  const countInactiveProjects = projects => {
    let inactiveProjects = 0;
    let inactiveProjects2 = 0;
    let inactiveArr = [];

    inactiveProjects2 = projects.reduce((acc, curr) => {
      if (curr.projectHours[0]) {
        const firstDate = new Date(curr.projectHours[0].created_at);
        const today = new Date();
        const diffMs = today - firstDate;
        const diffDays = Math.floor(diffMs / 86400000);
        if (diffDays >= 21) return (acc += 1);
      }
      return acc;
    }, 0);

    if (filters.employee) {
      inactiveArr = filteredProjects.map(project => {
        if (project.projectHours.length) {
          const a = project.projectHours.find(project => {
            const firstDate = new Date(project.created_at);
            const today = new Date();
            const diffMs = today - firstDate;
            const diffDays = Math.floor(diffMs / 86400000); // days
            return diffDays <= 21 && project.employee.name === filters.employee;
          });

          if (a) {
            return;
          }

          return project;
        }
      });

      inactiveArr = inactiveArr.filter(item => item !== undefined);

      inactiveProjects = inactiveArr.length;
    } else {
      inactiveProjects = projects.reduce((acc, curr) => {
        if (curr.projectHours[0]) {
          const firstDate = new Date(curr.projectHours[0].created_at);
          const today = new Date();
          const diffMs = today - firstDate;
          const diffDays = Math.floor(diffMs / 86400000);
          if (diffDays >= 21) return (acc += 1);
        }
        return acc;
      }, 0);
    }

    return {inactiveProjects, inactiveProjects2};
  };

  let kpiSource = [];
  if (!filteredProjects.length) {
    kpiSource = getNesteProjectValues(allProjects);
  } else {
    kpiSource = filteredProjects;
  }

  const {inactiveProjects, inactiveProjects2} = countInactiveProjects(kpiSource);



  let activeTotal = 0;
  let onholdTotal = 0;
  let saleTotal = 0;
  let ratingTotal = 0;
  let promoters = 0;
  let passive = 0;
  let detractor = 0;
  let promoterPercentage = 0;
  let passivePercentage = 0;
  let detractorPercentage = 0;
  let groeiKansen = 0;
  let inhoud = 0;
  let contact = 0;
  let groeiKansenGem = 0;
  let inhoudGem = 0;
  let contactGem = 0;

  if (!filteredProjects.length && !filters.response) {
    allProjects.forEach(project => {
      project.project.forEach(item => {
        onholdTotal += item.totalIn;
        activeTotal += item.total;
        saleTotal += item.totalSale;
        if (item.ratings.length) {
          ratingTotal += 1;
          item.ratings.forEach(rating => {
            if (rating.answers) {
              if (rating.answers[0]) {
                groeiKansen += rating.answers[0].value;
              }

              if (rating.answers[1]) {
                inhoud += rating.answers[1].value;
              }

              if (rating.answers[2]) {
                contact += rating.answers[2].value;
              }
            }

            if (rating.category && rating.category === 'promoter') {
              promoters += 1;
            }

            if (rating.category && rating.category === 'passive') {
              passive += 1;
            }

            if (rating.category && rating.category === 'detractor') {
              detractor += 1;
            }
          });
        }
      });
    });
  } else {
    filteredProjects.forEach(item => {
      onholdTotal += item.totalIn;
      activeTotal += item.total;
      saleTotal += item.totalSale;

      if (item.ratings.length) {
        ratingTotal += 1;
        item.ratings.forEach(rating => {
          if (rating.answers) {
            if (rating.answers[0]) {
              groeiKansen += rating.answers[0].value;
            }

            if (rating.answers[1]) {
              inhoud += rating.answers[1].value;
            }

            if (rating.answers[2]) {
              contact += rating.answers[2].value;
            }
          }

          if (rating.category && rating.category === 'promoter') {
            promoters += 1;
          }

          if (rating.category && rating.category === 'passive') {
            passive += 1;
          }

          if (rating.category && rating.category === 'detractor') {
            detractor += 1;
          }
        });
      }
    });
  }

  const setFilterInStorage = (key, value) => {
    const item = {
      value: value,
    };
    localStorage.setItem(key, JSON.stringify(item));
  };

  const handleHoursFilter = e => {
    e.preventDefault();
    const value = e.target.value;
    if (value !== 'default') {
      setFilters({target: {name: 'hours', value: value.toLowerCase()}});
      setFilterInStorage('hours', value.toLowerCase());
    } else {
      setFilters({target: {name: 'hours', value: null}});
      localStorage.removeItem('hours');
    }
  };

  const handleHoursFilter2 = e => {
    e.preventDefault();
    const value = e.target.value;
    if (value !== 'default') {
      setFilters({target: {name: 'hours2', value: value.toLowerCase()}});
      setFilterInStorage('hours2', value.toLowerCase());
    } else {
      setFilters({target: {name: 'hours2', value: null}});
      localStorage.removeItem('hours2');
    }
  };

  if (ratingTotal > 0) {
    promoterPercentage = (promoters / ratingTotal) * 100;
    passivePercentage = (passive / ratingTotal) * 100;
    detractorPercentage = (detractor / ratingTotal) * 100;
    groeiKansenGem = groeiKansen / ratingTotal;
    inhoudGem = inhoud / ratingTotal;
    contactGem = contact / ratingTotal;

    contactGem = Math.round(contactGem * 10) / 10;
    inhoudGem = Math.round(inhoudGem * 10) / 10;
    groeiKansenGem = Math.round(groeiKansenGem * 10) / 10;
  }

  const handleNpsClick = e => {
    const filterValue = {target: {name: 'response', value: e.target.className}};
    setFilters(filterValue);
    localStorage.setItem('response', e.target.className);
  };

  const removeResponse = () => {
    localStorage.removeItem('response');
    const filterValue = {target: {name: 'response', value: null}};
    setFilters(filterValue);
  };


  return (
    <>
      <div className="title">
        <div className="title__left">
          <div className="title__icon">
            <BsStarFill />
          </div>
          <h2>KPIS</h2>
        </div>
      </div>

      <div className="kpi-container">
        <div className="kpi-container__kpi">
          <div className="kpi-container__values">
            <span className="kpi-container__kpi-title">Actieve omzet</span>
            <span>{activeTotal}</span>
          </div>

          <div className="kpi-container__values">
            <span className="kpi-container__kpi-title">Onhold omzet</span>
            <span>{onholdTotal}</span>
          </div>

          <div className="kpi-container__values">
            <span className="kpi-container__kpi-title">Geoffreerd omzet</span>
            <span>{saleTotal}</span>
          </div>
        </div>

        <div className="kpi-container__kpi">
          <div className="kpi-container__values">
            <span className="kpi-container__kpi-title">Groeikansen</span>
            <span>{groeiKansenGem}</span>
          </div>

          <div className="kpi-container__values">
            <span className="kpi-container__kpi-title">Inhoud</span>
            <span>{inhoudGem}</span>
          </div>

          <div className="kpi-container__values">
            <span className="kpi-container__kpi-title">Contact</span>
            <span>{contactGem}</span>
          </div>
        </div>

        <div className="kpi-container__kpi">
          <div className="kpi-container__values">
            <span className="kpi-container__kpi-title">Promoters</span>

            {promoterPercentage > 0 ? (
              <span className="promoter" onClick={handleNpsClick}>
                {Math.round(promoterPercentage * 10) / 10}%
              </span>
            ) : (
              <span className="promoter">{promoterPercentage}%</span>
            )}
          </div>

          <div className="kpi-container__values">
            <span className="kpi-container__kpi-title">Passives</span>
            {passivePercentage > 0 ? (
              <span className="passive" onClick={handleNpsClick}>
                {Math.round(passivePercentage * 10) / 10}%
              </span>
            ) : (
              <span className="passive">{passivePercentage}%</span>
            )}
          </div>

          <div className="kpi-container__values">
            <span className="kpi-container__kpi-title">Detractors</span>

            {detractorPercentage > 0 ? (
              <span className="detractor" onClick={handleNpsClick}>
                {Math.round(detractorPercentage * 10) / 10}%
              </span>
            ) : (
              <span className="detractor">{detractorPercentage}%</span>
            )}
          </div>
        </div>
      </div>

      <div className="hours">
        <span>Projecten > 21 dagen geen uren geschreven:</span>
        {inactiveProjects2 > 0 ? (
          <>
            <button value={inactiveProjects} onClick={handleHoursFilter2}>
              {inactiveProjects2}
            </button>
          </>
        ) : (
          <>{inactiveProjects2}</>
        )}
        {filters.employee ? (
          <>
            <span>
              <br />
              &#8195; - Door {filters.employee}:
            </span>

            {inactiveProjects > 0 ? (
              <>
                <button value={inactiveProjects} onClick={handleHoursFilter}>
                  {inactiveProjects}
                </button>
              </>
            ) : (
              <>{inactiveProjects}</>
            )}
          </>
        ) : (
          ''
        )}
      </div>

      {filters.response || filters.hours || filters.hours2 ? (
        <div className="applied">
          <h4>Toegepaste filters:</h4>
          <span>
            {filters.response ? (
              <>
                <b>NPS Score</b> : {filters.response} <button onClick={removeResponse}> x </button>
              </>
            ) : null}
          </span>

          {filters.hours ? (
            <>
              <span>Projecten > 21 dagen geen uren geschreven: </span>Tonen
              <button value="default" onClick={handleHoursFilter}>
                X
              </button>
              <br />
            </>
          ) : null}

          {filters.hours2 ? (
            <>
              <span>Projecten > 21 dagen geen uren geschreven: </span>Tonen
              <button value="default" onClick={handleHoursFilter2}>
                X
              </button>
            </>
          ) : null}
        </div>
      ) : null}
    </>
  );
}
