import React, {useEffect, useState} from 'react';
import Modal from 'react-modal';
import './style.scss';
import {SimplicateApi} from '../../utils';
import jwtDecode from 'jwt-decode';
import {useToasts} from 'react-toast-notifications';

export default function HoursModal({modalIsOpen, handleModalClose, projectId, project}) {
  const [serviceData, setServiceData] = useState([]);
  const [hourType, setHourType] = useState({});
  const [apiData, setApiData] = useState({hours: null, note: ''});

  const {addToast} = useToasts();

  useEffect(() => {
    async function runEverything() {
      const token = localStorage.getItem('jwt');
      const decoded = jwtDecode(token);
      const name = decoded.name;

      const SimplicateRequest = new SimplicateApi(
        process.env.REACT_APP_SIMPLICATE_KEY,
        process.env.REACT_APP_SIMPLICATE_SECRET
      );
      const employee = await SimplicateRequest.get(`/hrm/employee/?q[name]=${name}&select=name,id`);
      const employeeId = employee.data[0].id;

      const projectServices = await SimplicateRequest.get(
        `/projects/service/?q[project_id]=${projectId}&select=name,id,hour_types`
      );
      const items = [];
      projectServices.data.forEach(item => {
        if (item.hour_types) {
          items.push(item);
        }
      });
      setApiData(prev => {
        return {...prev, projectId: items[0].project_id, employeeId: employeeId};
      });
      setServiceData(items);
    }

    runEverything();
  }, [projectId]);

  const handleModalSubmit = async e => {
    e.preventDefault();
    const SimplicateRequest = new SimplicateApi(
      process.env.REACT_APP_SIMPLICATE_KEY,
      process.env.REACT_APP_SIMPLICATE_SECRET
    );

    const body = {
      employee_id: apiData.employeeId,
      project_id: apiData.projectId,
      projectservice_id: apiData.serviceId,
      type_id: apiData.hourTypeId,
      hours: apiData.hours,
      start_date: apiData.date,
      note: apiData.note,
    };

    const res = await SimplicateRequest.post(`/hours/hours`, body);

    if (res.errors) {
      console.log(res.errors[0]);

      addToast(res.errors[0], {
        appearance: 'warning',
        autoDismiss: true,
      });
    }

    handleModalClose();
  };

  const handleServiceChange = e => {
    const val = e.target.value;
    if (e.target.value === 'Selecteer dienst') {
      setHourType({label: 'Uren schrijven niet mogelijk'});
    } else {
      const hourType = serviceData.find(item => item.id === e.target.value).hour_types;

      if (hourType) {
        hourType.forEach(hour => {
          setHourType({label: hour.hourstype.label, id: hour.hourstype.id});

          setApiData(prev => {
            return {...prev, hourTypeId: hour.hourstype.id, serviceId: val};
          });
        });
      } else {
        setHourType({label: 'Uren schrijven niet mogelijk'});
      }
    }
  };

  const handleModalChange = e => {
    const name = e.target.name;
    const val = e.target.value;

    console.log(name, val);

    setApiData(prev => {
      return {...prev, [name]: val};
    });
  };

  return (
    <div>
      <Modal
        isOpen={modalIsOpen}
        onRequestClose={handleModalClose}
        ariaHideApp={false}
        style={{
          overlay: {
            background: 'rgba(0, 0, 0, 0.3)',
          },
          content: {
            width: '700px',
            height: '500px',
            margin: '0 auto',
          },
        }}
      >
        <div className="modal-container">
          <form className="modal-form" onSubmit={handleModalSubmit}>
            <label>Kies een project</label>
            <input
              type="text"
              className="modal-form-project"
              defaultValue={project.projectName}
            ></input>
            <br />
            <label>Kies een dienst</label>
            <select className="modal-service-select" onChange={handleServiceChange}>
              <option value="Selecteer dienst">Selecteer dienst</option>

              {serviceData.length
                ? serviceData.map(item => <option value={item.id}>{item.name}</option>)
                : ''}
            </select>

            <div className="modal-form">
              {apiData.serviceId ? (
                <>
                  <label>Urensoort</label> <p className="hour-type">{hourType.label}</p>
                </>
              ) : null}
              <br />
              {apiData.serviceId ? (
                <>
                  <label>Aantal uren</label>{' '}
                  <input
                    type="text"
                    className="hour-type"
                    placeholder="Aantal uren"
                    name="hours"
                    onChange={handleModalChange}
                  ></input>
                </>
              ) : null}
              <br />
              {apiData.serviceId ? (
                <>
                  <label>Notitie</label>
                  <textarea
                    type="text"
                    className="hour-type"
                    placeholder="Opmerking"
                    name="note"
                    onChange={handleModalChange}
                  ></textarea>
                </>
              ) : null}
              <br />
              {apiData.serviceId ? (
                <>
                  <label>Startdatum</label>
                  <input
                    className="modal-date"
                    type="date"
                    name="date"
                    onChange={handleModalChange}
                  />
                </>
              ) : (
                ''
              )}
            </div>

            {apiData.hours ? (
              <button className="modal-submit" type="submit">
                Verzenden
              </button>
            ) : (
              ''
            )}
          </form>
        </div>
        <div className="close-button" onClick={() => handleModalClose()}>
          X
        </div>
      </Modal>
    </div>
  );
}
