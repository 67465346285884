import React from 'react';
import './style.scss';

export default function HourTable({project}) {
  return (
    <div className="table">
      <table>
        <thead>
          <tr>
            <th>Datum</th>
            <th>Medewerker</th>
            <th>Dienst</th>
            <th>Toelichting</th>
            <th>Aantal</th>
          </tr>
        </thead>

        <tbody>
          {project.projectHours.slice(0, 20).map(hour => (
            <tr key={hour.id}>
              <td>{hour.created_at}</td>
              <td>{hour.employee.name}</td>
              <td>{hour.type.label}</td>
              <td className="note">{hour.note}</td>
              <td className="hour-">{hour.hours.toFixed(2)}</td>
            </tr>
          ))}
        </tbody>
      </table>

      {project.projectHours.length ? (
        <a
          href={`https://emark.simplicate.nl/projects/${project.projectId}/hours`}
          target="_blank"
          rel="noopener noreferrer"
        >
          Bekijken in Simplicate
        </a>
      ) : (
        'Dit project bevat geen urenoverzicht'
      )}
    </div>
  );
}
