import React, {useEffect, useState} from 'react';
import './style.scss';
import Accordion from './Accordion';
import axios from 'axios';
import {getInitials} from '../../../utils';
import revenueGroups from './revenueGroups';
import {SimplicateApi} from '../../../utils';
import {FaExternalLinkAlt} from 'react-icons/fa';

export default function Sidebar({project}) {
  const [initials, setInitials] = useState();
  const [fieldImportance, setFieldImportance] = useState();
  const [fieldStartDate, setFieldStartDate] = useState();
  const [verslagB, setVerslagB] = useState();
  const [postValues, setPostValues] = useState('');
  const [fieldValues, setFieldValues] = useState([]);

  const [dataStudioUrl, setDataStudioUrl] = useState('');
  const [growPlan, setGrowPlan] = useState();

  const [analyticsEmail, setAnalyticsEmail] = useState();

  const [fieldKpi, setFieldKpi] = useState();
  const [importantFiles, setImportantFiles] = useState();
  const [marketingMix, setMarketingMix] = useState();
  const [audience, setAudience] = useState();

  const [analyticsEdit, setAnalyticsEdit] = useState(false);
  const [dataStudioEdit, setDataStudioEdit] = useState(false);

  const SimplicateRequest = new SimplicateApi(
    process.env.REACT_APP_SIMPLICATE_KEY,
    process.env.REACT_APP_SIMPLICATE_SECRET
  );

  useEffect(() => {
    setInitials(getInitials());
  }, []);

  useEffect(() => {
    let config = {
      headers: {
        'x-auth-token': localStorage.getItem('jwt'),
        'Access-Control-Allow-Origin': '*',
      },
      params: {
        id: project.projectId,
      },
    };

    axios
      .get(`${process.env.REACT_APP_API_HOST}/cm/project-fields`, config)
      .then(res => {
        const response = res.data[0];

        if (response && response.importance) {
          setFieldImportance(`${initials}\n ${response.importance}`);
        } else {
          setFieldImportance(`${initials}\n`);
        }

        if (response && response.startDate) {
       
          setFieldStartDate(response.startDate);
        }

        if (response && response.salesReport) {
          setVerslagB(`${initials}\n ${response.salesReport}`);
        } else {
          setVerslagB(`${initials}\n`);
        }

        if (response && response.businessModel) {
          setGrowPlan(`${initials}\n ${response.businessModel}`);
        } else {
          setGrowPlan(`${initials}\n`);
        }

        if (response && response.goal) {
          setFieldKpi(`${initials}\n  ${response.goal}`);
        } else {
          setFieldKpi(`${initials}\n`);
        }

        if (response && response.audience) {
          setAudience(`${initials}\n  ${response.audience}`);
        } else {
          setAudience(`${initials}\n`);
        }

        if (response && response.marketingMix) {
          setMarketingMix(`${initials}\n  ${response.marketingMix}`);
        } else {
          setMarketingMix(`${initials}\n`);
        }

        if (response && response.importantFiles) {
          setImportantFiles(`${initials}\n  ${response.importantFiles}`);
        } else {
          setImportantFiles(`${initials}\n`);
        }

        if (response && response.analyticsEmail) {
          setAnalyticsEmail(`${response.analyticsEmail}`);
        }

        if (response && response.dataStudioUrl) {
          setDataStudioUrl(`${response.dataStudioUrl}`);
        }
      })
      .catch(err => console.log(err.response));
  }, [project, initials]);

  const handleChange = e => {
    const value = e.target.value;
    setPostValues(prev => {
      return value;
    });
  };

  const handleBlur = () => {
    const body = {projectId: project.projectId, fields: {importance: postValues}};
    const options = {
      headers: {
        'x-auth-token': localStorage.getItem('jwt'),
        'Access-Control-Allow-Origin': '*',
      },
    };

    if (postValues === fieldImportance) {
      return;
    } else {
      axios
        .post(`${process.env.REACT_APP_API_HOST}/cm/project-fields`, body, options)
        .then(res => console.log(res))
        .catch(err => {
          console.error(err);
        });
    }
  };

  const handleDateBlur = e => {
    e.preventDefault();
    const name = e.target.name;

    if(name === 'salesReport'){

      const simplicateBody = {
        'custom_fields' : [{'verslag_b' : fieldValues[0].value}],
      }

      console.log(fieldValues)

      SimplicateRequest.put(`/crm/organization/${project.companyId}`, simplicateBody).then(response => {
        console.log(response);
      });
    }
    if (fieldValues.length) {
      fieldValues.forEach(value => {
        const body = {projectId: value.id, fields: {[value.field]: value.value || '\n'}};
        const options = {
          headers: {
            'x-auth-token': localStorage.getItem('jwt'),
            'Access-Control-Allow-Origin': '*',
          },
        };

        axios
          .post(`${process.env.REACT_APP_API_HOST}/cm/project-fields`, body, options)
          .then(res => {
            console.log(res);
          })
          .catch(err => {
            console.error(err);
          });
      });
    }
    setFieldValues([]);
  };

  const handleChange1 = e => {
    const {value, name, id} = e.target;

    setFieldValues(prev => {
      const existingIndex = prev.findIndex(el => el.field === name && el.id === id);
      const newFields = [...prev];
      if (existingIndex >= 0) {
        newFields[existingIndex] = {...newFields[existingIndex], value};
        return newFields;
      }
      newFields.push({value: value, field: name, id: id});
      return newFields;
    });
  };

  const sortedSales = project.saleData.sort(
    (a, b) => new Date(b.salesDate) - new Date(a.salesDate)
  );

  return (
    <div className="sidebar">
      <div className="sidebar__section">
        <Accordion title={`klanttevredenheid` + (project.ratings.length && project.ratings[0] ? ` (NPS : ${project.ratings[0].rating})` : ' (Geen)') }  open={false}>
          {project.ratings.length && project.ratings[0].date ? (
            <div>
              {new Date(project.ratings[0].date).toISOString().split('T')[0]} NPS Score:{' '}
              {project.ratings[0].rating}
            </div>
          ) : null}
          <div>
            <span>Wat vind de klant belangrijk</span>
          </div>
          <textarea
            defaultValue={fieldImportance}
            name={'importance'}
            id={project.projectId}
            onChange={handleChange1}
            onBlur={handleDateBlur}
          ></textarea>
        </Accordion>

        <Accordion title={"Actieve diensten" +  (project.total ? ` (€ ${project.total})` : ' (Geen)')} open={false}>
          {project.revenueGroups.map(obj =>
            Object.keys(obj).map(item =>
              obj[item] !== 0 && item !== '_id' ? (
                <p key={Math.random()}>{`${revenueGroups[item]} : €${obj[item]} per maand`}</p>
              ) : null
            )
          )}
          <p>Totaal: €{project.total} per maand</p>
          <p>
            <br />
            Startdatum eerste abo:{' '}
            <b>
              <a href={project.projectUrl} target="_blank" rel="noopener noreferrer">
                {project.earliestDate}
              </a>
            </b>
          </p>

          <p>
            <br />
            Evaluatie maanden:{' '}
            <b>
              {project.firstEvaluation} & {project.secondEvaluation}
            </b>
          </p>
        </Accordion>

        <Accordion title={"Onhold diensten" +  (project.totalIn ? ` (€ ${project.totalIn})` : ' (Geen)')} open={false}>
          {project.revenueGroupsIn.map(obj =>
            Object.keys(obj).map(item =>
              obj[item] !== 0 && item !== '_id' ? (
                <p
                  key={item + Math.random()}
                >{`${revenueGroups[item]}: €${obj[item]} per maand`}</p>
              ) : null
            )
          )}
          {project.totalIn !== 0 ? (
            <div className="onhold-block">
              <p>Totaal: €{project.totalIn} per maand</p>
              <p>Verwachte startdatum</p>
              <input
                type="date"
                name="fieldStartDate"
                onChange={handleChange1}
                onBlur={handleDateBlur}
                id={project.projectId}
                defaultValue={project.fieldStartDate}
              ></input>
            </div>
          ) : null}
        </Accordion>

        <Accordion title={"Saleskansen" + (project.totalSale ? ` (€ ${project.totalSale})` : ' (Geen)')} open={false}>
          {project.sales.map(obj =>
            Object.keys(obj).map(item =>
              obj[item] !== 0 && item !== '_id' ? (
                <p
                  key={item + Math.random()}
                >{`${revenueGroups[item]}: €${obj[item]} per maand`}</p>
              ) : null
            )
          )}

          {project.totalSale !== 0 ? (
            <p>
              <br />
              Totaal: €{project.totalSale} per maand
            </p>
          ) : null}

          {sortedSales.map(sale => (
            <p>
              <a
                href={`https://emark.simplicate.nl/sales/${sale.id}/services`}
                key={Math.random()}
                target="_blank"
                rel="noopener noreferrer"
              >
                {sale.saleSubject} | {sale.salesDate}
              </a>
            </p>
          ))}

          <span>Sales verslag:</span>
          <textarea
            defaultValue={verslagB}
            name={'salesReport'}
            id={project.projectId}
            onChange={handleChange1}
            onBlur={handleDateBlur}
          ></textarea>

          <span>Sales: </span>
          <a
            href={`https://emark.simplicate.nl/crm/organizations/${project.companyId}/sales`}
            target="_blank"
            rel="noopener noreferrer"
          >
            {project.companyName}
          </a>
        </Accordion>

        <h4>Belangrijke project informatie</h4>

        <Accordion title="Businessmodel" open={false}>
          <span>Business model bedrijf:</span>
          <textarea
            defaultValue={growPlan}
            name={'businessModel'}
            id={project.projectId}
            onChange={handleChange1}
            onBlur={handleDateBlur}
          ></textarea>
        </Accordion>

        <Accordion title="Doelstelling & KPI's" open={false}>
          <span>Doelstelling, KPI's & budget:</span>
          <textarea
            defaultValue={fieldKpi}
            name={'goal'}
            id={project.projectId}
            onChange={handleChange1}
            onBlur={handleDateBlur}
            className="kpis"
          ></textarea>
        </Accordion>

        <Accordion title="Doelgroep" open={false}>
          <span>Doelgroep:</span>
          <textarea
            defaultValue={audience}
            name={'audience'}
            id={project.projectId}
            onChange={handleChange1}
            onBlur={handleDateBlur}
            className="kpis"
          ></textarea>
        </Accordion>

        <Accordion title="Marketingmix" open={false}>
          <span>Marketingmix:</span>
          <textarea
            defaultValue={marketingMix}
            name={'marketingMix'}
            id={project.projectId}
            onChange={handleChange1}
            onBlur={handleDateBlur}
            className="m-mix"
          ></textarea>
        </Accordion>

        <Accordion title="Belangrijk bestanden" open={false}>
          <span>Belangrijke bestanden:</span>
          <textarea
            defaultValue={importantFiles}
            name={'importantFiles'}
            id={project.projectId}
            onChange={handleChange1}
            onBlur={handleDateBlur}
            className="kpis"
          ></textarea>
        </Accordion>

        <Accordion title="Tools & koppelingen" open={false}>
          <div className="extra-info">
            {(analyticsEmail && analyticsEmail !== 'not set' && analyticsEmail !== 'Geen') ||
            analyticsEdit ? (
              <div>
                <span>Data studio e-mail: </span>

                {analyticsEdit ? (
                  <>
                    <input
                      type="text"
                      defaultValue={analyticsEmail}
                      name="analyticsEmail"
                      className="analyticsEmail"
                      onChange={e => {
                        handleChange1(e);
                        setAnalyticsEmail(e.target.value);
                      }}
                      onBlur={e => {
                        handleDateBlur(e);
                        setAnalyticsEdit(false);
                      }}
                      id={project.projectId}
                    ></input>
                  </>
                ) : (
                  <>
                    <span
                      onClick={() => {
                        setAnalyticsEdit(true);
                      }}
                    >
                      {analyticsEmail + ' '}
                    </span>
                  </>
                )}
                <a
                  href="https://accounts.google.com/signin/v2/identifier?hl=en&continue=https%3A%2F%2Fwww.google.com%2Fsearch%3Fsource%3Dhp%26ei%3D90-PXrbbMJGXkwWdoaSgCQ%26q%3Dgoogle%2Blogin%2Burl%26oq%3Dgoogle%2Blogin%2Burl%26gs_lcp%3DCgZwc3ktYWIQAzICCAAyAggAMgYIABAWEB4yBggAEBYQHjIGCAAQFhAeMgYIABAWEB4yBggAEBYQHjIGCAAQFhAeMgYIABAWEB4yBggAEBYQHjoECAAQCkonCBcSIzQ1Zzg3ZzUzZzg3ZzY2Zzc5Zzk4Zzc4Zzc3ZzY4Zzk5ZzgzShsIGBIXMWcxZzFnMWcxZzFnMWcxZzFnMWc2ZzJQhkhY7VpgwVxoAXAAeACAAXuIAZ0LkgEEMTYuMpgBAKABAaoBB2d3cy13aXqwAQA%26sclient%3Dpsy-ab%26ved%3D0ahUKEwj2stuv5NvoAhWRy6QKHZ0QCZQQ4dUDCAc%26uact%3D5&flowName=GlifWebSignIn&flowEntry=AddSession"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <FaExternalLinkAlt />
                </a>
              </div>
            ) : (
              <div>
                <span
                  onClick={() => {
                    setAnalyticsEdit(true);
                  }}
                >
                  Data studio e-mail: Geen
                </span>
              </div>
            )}

           
            {(dataStudioUrl && dataStudioUrl !== 'Geen' &&  dataStudioUrl.length > 4) || dataStudioEdit ? (
              <div className="dataStudioUrl">
                <span>Dashboard url: </span>
                {dataStudioEdit ? (
                  <input
                    type="text"
                    defaultValue={dataStudioUrl}
                    name="dataStudioUrl"
                    id={project.projectId}
                    onChange={e => {
                      handleChange1(e);
                      setDataStudioUrl(e.target.value);
                    }}
                    onBlur={e => {
                      handleDateBlur(e);
                      setDataStudioEdit(false);
                    }}
                  ></input>
                ) : (
                  <span
                    onClick={() => {
                      setDataStudioEdit(true);
                    }}
                  >
                  
                    {dataStudioUrl ? dataStudioUrl.substring(0, 30) + '...' : null}
                  </span>
                )}
                <a href={dataStudioUrl} target="_blank" rel="noopener noreferrer">
                  <FaExternalLinkAlt />
                </a>
              </div>
            ) : (
              <div>
                <span
                  onClick={() => {
                    setDataStudioEdit(true);
                  }}
                >
                  Dashboard url: Geen
                </span>
              </div>
            )}
          </div>
        </Accordion>
      </div>
    </div>
  );
}
