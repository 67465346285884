import React, {useState} from 'react';
import {FaPlus, FaMinus} from 'react-icons/fa';

export default function Accordion({open, title, children}) {
  const [show, setShow] = useState(open);

  const handleClick = () => {
    setShow(!show);
  };

  return (
    <>
      <div className="accordion" onClick={handleClick}>
        <span>{title}</span>
        <span>{show ? <FaMinus /> : <FaPlus />}</span>
      </div>
      <div className={!show ? 'accordion-content' : 'accordion-content active'}>{children}</div>
    </>
  );
}
